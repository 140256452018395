import { Currency } from '@/lib/currency';

export function money(value: number, currency?: Currency): string {
  const opts: any = {};
  if (currency !== undefined) {
    opts.currency = currency;
    opts.style = 'currency';
  }
  return Intl.NumberFormat(window.navigator.language, opts).format(+value);
}
