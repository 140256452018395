






















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Quill from '@/components/Quill.vue';

import {
  Invoice,
  InvoiceStatus,
  InvoiceTextEmail,
  InvoiceType,
} from '@/models/invoice';
import { readFileBase64, unwrapError } from '@/lib/helpers';
import { Attachment } from '@/models';
import { EmailTextService, ProfileService } from '@/lib/services';
import { newi18n, toLanguage } from '@/plugins/i18n';
import { EmailHint } from '@/lib/emailhint';
import { namespace } from 'vuex-class';

export type ModalCallback = (
  type: string,
  invoice: Invoice,
  emailData: InvoiceTextEmail,
) => Promise<any>;

const sSettings = namespace('settings');

@Component({
  components: {
    Quill,
  },
})
export default class SendInvoiceModal extends Vue {
  @Prop({ required: true, type: Invoice })
  invoice: Invoice;

  @Prop({ required: true })
  callback: ModalCallback;

  @Prop({ default: false, type: Boolean })
  onlyEmail: boolean;

  @Prop({ default: false, type: Boolean })
  allowSms: boolean;

  // By default we will determine the type using the invoice.type property.
  // If you need to override this behaviour, you can override it using this.
  @Prop({ default: null })
  type: string | null;

  // Hint the type of sending we are going to do.
  // By default tries to figure it out on its own
  @Prop()
  hint: EmailHint;

  @sSettings.Getter('hasSms') hasSms: boolean;

  step: number = 1;
  error: string = '';
  loading: boolean = false;
  textLoading: boolean = false;

  emailData: InvoiceTextEmail = new InvoiceTextEmail();

  async mounted(): Promise<void> {
    Object.assign(this.emailData, this.invoice.text.email);
    if (this.emailData.isEmpty) {
      this.textLoading = true;
      const profile = await ProfileService.get(+this.invoice.sender.id);
      let emailHint: EmailHint;
      if (this.hint) {
        emailHint = this.hint;
      } else {
        emailHint = this.guessHint();
      }

      try {
        const text = await EmailTextService.get(+profile[emailHint.profileKey]);
        this.emailData.subject = text.subject;
        this.emailData.body = text.text;
      } catch (e) {
        const i18n = await newi18n(toLanguage(this.invoice.meta.language));
        this.emailData = emailHint.defaultText(i18n);
      } finally {
        this.textLoading = false;
      }
    }

    if (this.onlyEmail) this.step = 2;
  }

  guessHint(): EmailHint {
    if (this.invoice.status === InvoiceStatus.SENT) {
      return EmailHint.REMIND;
    }

    if (this.invoice.isQuotation) {
      return EmailHint.QUOTATION;
    }

    return EmailHint.SEND;
  }

  async download(): Promise<void> {
    this.loading = true;
    try {
      await this.callback('download', this.invoice, this.emailData);
    } catch (e) {
      this.$toaster.error('Could not send invoice', unwrapError(e));
    } finally {
      this.loading = false;
    }
  }

  async sms(): Promise<void> {
    this.loading = true;
    try {
      await this.callback('sms', this.invoice, this.emailData);
    } catch (e) {
      this.$toaster.error('Could not send invoice', unwrapError(e));
    } finally {
      this.loading = false;
    }
  }

  async onAttachmentSelect(e: Record<string, any>): Promise<void> {
    if (!e.target.files) {
      this.emailData.attachment = null;
      return;
    }
    const file: File = e.target.files[0];

    this.loading = true;
    try {
      const attachment = new Attachment();
      attachment.filename = file.name;
      attachment.content = await readFileBase64(file);
      this.emailData.attachment = attachment;
    } catch (e) {
      this.error = unwrapError(e);
    } finally {
      this.loading = false;
    }
  }

  async onSubmit(): Promise<void> {
    this.error = '';
    if (!this.emailData.body) {
      // Oh no, the user is retarded
      this.error = 'Body is required';
      return;
    }

    this.loading = true;
    try {
      await this.callback('email', this.invoice, this.emailData);
      this.close();
    } catch (e) {
      this.$toaster.error('Could not send invoice', unwrapError(e));
      // Ignored
    } finally {
      this.loading = false;
    }
  }

  close(): void {
    this.$emit('close');
  }

  get shouldShowSmsButton(): boolean {
    return !!(this.hasSms && this.allowSms && this.invoice.receiver.phone);
  }

  get linkClasses(): Record<string, boolean> {
    return {
      'is-loading': this.loading,
    };
  }

  get invoiceType(): string {
    if (this.type) {
      return this.type;
    }

    if (this.invoice.isQuotation) {
      return 'quotation';
    }

    if (this.invoice.type === InvoiceType.CREDIT) {
      return 'credit';
    }

    return 'invoice';
  }
}
