import VueI18n from 'vue-i18n';
import { InvoiceTextEmail } from '@/models';

interface EmailHintTranslationKeys {
  subject: string;
  body: string;
}

/**
 * Type is a typesafety enum for determining hint type.
 * Not accessible outside and not meant to be.
 * Use the constants on EmailHint instead
 */
const enum Type {
  SEND,
  REMIND,
  QUOTATION,
}

/**
 * Provides some extracted logic for hinting email text.
 */
export class EmailHint {
  public static SEND = new EmailHint(Type.SEND);
  public static REMIND = new EmailHint(Type.REMIND);
  public static QUOTATION = new EmailHint(Type.QUOTATION);

  private constructor(private type: Type) {}

  /**
   * Resolve the profile key for the given hint
   */
  get profileKey(): string {
    switch (this.type) {
      case Type.REMIND:
        return 'emailTextReminderId';
      case Type.QUOTATION:
        return 'emailTextQuotationId';
      case Type.SEND:
      default:
        return 'emailTextId';
    }
  }

  /**
   * Resolve translation keys for the current hint
   */
  get translationKeys(): EmailHintTranslationKeys {
    let key;
    switch (this.type) {
      case Type.REMIND:
        key = 'reminder';
        break;
      case Type.QUOTATION:
        key = 'quotation';
        break;
      case Type.SEND:
        key = 'invoice';
    }

    return {
      subject: 'email.default.subject',
      body: 'email.default.' + key,
    };
  }

  /**
   * Generates an InvoiceTextEmail using the given hint and translation instance.
   *
   * @param i18n An instance of VueI18n to translate with
   */
  defaultText(i18n: VueI18n): InvoiceTextEmail {
    const text = new InvoiceTextEmail();
    const keys = this.translationKeys;

    text.subject = i18n.tc(keys.subject);
    text.body = i18n.tc(keys.body);

    return text;
  }
}
