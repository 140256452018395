






































































































































































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ErrorList from '@/components/ErrorList.vue';
import CountrySelector from '@/components/form/CountrySelector.vue';

import { CustomerService } from '@/lib/services';
import { Customer } from '@/models';
import { validate, ValidationError } from 'class-validator';
import { unwrapError } from '@/lib/helpers';
import { Language } from '@/plugins/i18n';

const sSettings = namespace('settings');

@Component({
  components: {
    ErrorList,
    CountrySelector,
  },
  data() {
    return { Language };
  },
})
export default class CustomerEditModal extends Vue {
  @Prop({ type: Boolean })
  showBack: boolean;
  @Prop({ type: Boolean, default: true })
  showHeader: boolean;
  @Prop({ default: false, type: Boolean })
  edit: boolean;
  @Prop({ default: () => new Customer(), type: Customer })
  customer: Customer;
  @Prop({ default: null })
  handler: (customer: Customer) => Promise<number>;
  @Prop({ default: true, type: Boolean })
  showVatChecker: boolean;

  @sSettings.State('country') defaultCountry: string;

  showExtraSettings: boolean = false;

  // Is loading VAT data
  loading: boolean = false;
  // VAT error message
  error: string = '';

  customerErrors: ValidationError[] = [];

  mounted(): void {
    if (!this.customer.country) {
      this.customer.country = this.defaultCountry;
    }
  }

  back(): void {
    this.$emit('back');
  }

  async onSubmit(): Promise<void> {
    if (this.edit) {
      await this.updateCustomer();
    } else {
      await this.createCustomer();
    }
  }

  async validate(): Promise<boolean> {
    this.customerErrors = [];
    this.customerErrors = await validate(this.customer);
    return this.customerErrors.length === 0;
  }

  async updateCustomer(): Promise<void> {
    if (!(await this.validate())) return;

    try {
      await (this.handler || CustomerService.update.bind(CustomerService))(
        this.customer,
      );
      this.$emit('updated', this.customer);
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.customer'),
        unwrapError(e),
      );
    }
  }

  async createCustomer(): Promise<void> {
    if (!(await this.validate())) return;

    if (!this.customer.country) {
      // Extra default here, for extra defaultness
      this.customer.country = this.defaultCountry || 'NL';
    }

    try {
      const id = await (
        this.handler || CustomerService.create.bind(CustomerService)
      )(this.customer);
      this.customer.id = id;
      this.$emit('created', this.customer);
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.create.customer'),
        unwrapError(e),
      );
    }
  }

  async lookup(): Promise<void> {
    const lookupNumber = this.customer.vatNumber;
    if (!lookupNumber || lookupNumber.length < 2) return;

    this.loading = true;
    this.error = '';
    const possibleCountryCode = lookupNumber.slice(0, 2);
    if (/[a-z]{2}/i.test(possibleCountryCode)) {
      // VAT number includes country
      this.customer.country = possibleCountryCode.toUpperCase();
      this.customer.vatNumber = lookupNumber.slice(2);
    }

    try {
      // vatNumber cannot be null at this point
      const result = await CustomerService.checkVat(
        this.customer.country,
        this.customer.vatNumber as string,
      );
      this.customer.name = result.name;
      this.customer.address = result.street.replace(
        /0+([0-9]\d*)/,
        (x) => `${+x}`,
      );
      this.customer.city = result.city;
      this.customer.zipcode = result.zip;
    } catch (e) {
      this.error =
        (e as any).response.data.error.message ||
        this.$tc('messages.error.unknown');
    } finally {
      this.loading = false;
    }
  }
}
