
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { format, startOfTomorrow, endOfToday, isAfter } from 'date-fns';
import { DATE_FORMAT_ISO } from '@/lib/constants';

@Component
export default class SepaDateModal extends Vue {
  @Prop({ required: true })
  callback: (date: Date) => Promise<void>;

  error: string = '';
  date: string = format(startOfTomorrow(), DATE_FORMAT_ISO);
  loading: boolean = false;

  async onSubmit(): Promise<void> {
    this.error = '';
    if (!isAfter(this.date, endOfToday())) {
      this.error = 'Sepa collection date must be at least tomorrow';
      return;
    }

    this.loading = true;
    try {
      await this.callback(new Date(this.date));
      this.close();
    } catch (e) {
      // Ignored
    } finally {
      this.loading = false;
    }
  }

  close(): void {
    this.$emit('close');
  }

  get linkClasses(): Record<string, boolean> {
    return {
      'is-loading': this.loading,
    };
  }
}
