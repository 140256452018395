









import Vue from 'vue';
import { Component, Model, Watch } from 'vue-property-decorator';

import SearchInput from './SearchInput.vue';

interface CountryOption {
  name: string;
  code: string;
}

@Component({
  components: {
    SearchInput,
  },
})
export default class CountrySelector extends Vue {
  options: CountryOption[] = [];

  selected: CountryOption | null = null;

  @Model('change')
  code!: string;

  async mounted(): Promise<void> {
    this.setupCountries();
    if (this.code) {
      this.setByCode(this.code);
    }
  }

  onSelect(option: CountryOption): void {
    this.$emit('change', option.code);
  }

  @Watch('code')
  setByCode(code?: string): void {
    if (!code) return;
    const properCode = code.toUpperCase();
    this.selected = this.options.find((e) => e.code === properCode) || null;
  }

  setupCountries(): void {
    const rawCountries = this.$t('_.country');
    for (const key of Object.keys(rawCountries)) {
      this.options.push({
        code: key,
        name: rawCountries[key],
      });
    }
  }
}
